import React from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { images } from '../../constants';
import './Testimonials.css';

const Testimonials = () => {
    const testimonials = [
        {
            "image": images.customer,
            "message": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit velit, repellat magni maxime repellendus, iure a at adipisci voluptatibus dicta atque, nihil sed cum molestiae inventore. Inventore ex provident vitae? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis obcaecati, pariatur, maiores aperiam totam nisi illo nihil odio beatae itaque architecto repudiandae eum unde quis temporibus, nam debitis ullam repellat! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id corporis accusantium est deleniti reprehenderit iusto omnis laboriosam eveniet et unde, animi aliquam, voluptas placeat, inventore voluptate nulla excepturi deserunt hic.",
            "name": "Utilisateur 1",
            "field": "PDG AEK Group",
        },
        {
            "image": images.customer2,
            "message": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit velit, repellat magni maxime repellendus, iure a at adipisci voluptatibus dicta atque, nihil sed cum molestiae inventore. Inventore ex provident vitae? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis obcaecati, pariatur, maiores aperiam totam nisi illo nihil odio beatae itaque architecto repudiandae eum unde quis temporibus, nam debitis ullam repellat! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id corporis accusantium est deleniti reprehenderit iusto omnis laboriosam eveniet et unde, animi aliquam, voluptas placeat, inventore voluptate nulla excepturi deserunt hic.",
            "name": "Utilisateur 2",
            "field": "Compta AEK Group",
        },
        {
            "image": images.customer,
            "message": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit velit, repellat magni maxime repellendus, iure a at adipisci voluptatibus dicta atque, nihil sed cum molestiae inventore. Inventore ex provident vitae? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis obcaecati, pariatur, maiores aperiam totam nisi illo nihil odio beatae itaque architecto repudiandae eum unde quis temporibus, nam debitis ullam repellat! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id corporis accusantium est deleniti reprehenderit iusto omnis laboriosam eveniet et unde, animi aliquam, voluptas placeat, inventore voluptate nulla excepturi deserunt hic.",
            "name": "Utilisateur 3",
            "field": "RH AEK Group",
        },
        {
            "image": images.customer2,
            "message": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit velit, repellat magni maxime repellendus, iure a at adipisci voluptatibus dicta atque, nihil sed cum molestiae inventore. Inventore ex provident vitae? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis obcaecati, pariatur, maiores aperiam totam nisi illo nihil odio beatae itaque architecto repudiandae eum unde quis temporibus, nam debitis ullam repellat! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id corporis accusantium est deleniti reprehenderit iusto omnis laboriosam eveniet et unde, animi aliquam, voluptas placeat, inventore voluptate nulla excepturi deserunt hic.",
            "name": "Utilisateur 4",
            "field": "Assistant AEK Group",
        }
    ];
    return (
        <div className='container flex flex-col gap-12 py-20 mx-auto custom-border-radius bg-slate-100'>
            <h3 className='px-40 text-5xl font-bold text-center'>Client Reviews</h3>
            <div className='flex flex-row justify-end gap-6 px-40'>
                <div className='p-2 rounded-full cursor-pointer bg-slate-900 w-fit'>
                    <img src={images.arrowWhite} alt="" />
                </div>
                <div className='p-2 bg-blue-700 rounded-full cursor-pointer hover:bg-blue-900 w-fit'>
                    <img src={images.arrowWhite} alt="" className='rotate-180' />
                </div>
            </div>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                className='w-full'
            >
                {
                    testimonials.map((testimonial) => {
                        return <SwiperSlide className='px-40'>
                            <div className='flex flex-row gap-12'>
                                <img src={testimonial.image} alt="" className='custom-customer-border-radius' />
                                <div className='flex flex-col items-start gap-8'>
                                    <img src={images.quotation} className="h-12 mt-2" alt="" />
                                    <p className='text-lg italic text-gray-700'>
                                        {
                                            testimonial.message
                                        }
                                    </p>

                                    <div className='flex flex-row'>
                                        <div className='flex flex-col flex-1 w-full gap-3'>
                                            <h6 className='text-2xl font-semibold text-blue-700'>Nom du client</h6>
                                            <div className='flex flex-row gap-1 text-xl'>
                                                <h6 className='font-semibold text-gray-700'>{testimonial.field}</h6>
                                                <p>-</p>
                                                <h4>{
                                                    testimonial.name
                                                }</h4>
                                            </div>
                                        </div>
                                        <img src={images.quotation} alt="" className='opacity-10' />
                                    </div>
                                </div>

                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
            <div className='flex flex-row items-center justify-center gap-4 mt-10'>
                <div className='p-4 rounded-full w-fit bg-slate-800'>
                    <div className='w-3 h-3 p-2 bg-blue-700 rounded-full'>
                    </div>
                </div>
                <div className='p-3 rounded-full w-fit bg-slate-800'>
                    <div className='w-1 h-1 p-2 bg-blue-700 rounded-full'>
                    </div>
                </div>
                <div className='p-3 rounded-full w-fit bg-slate-800'>
                    <div className='w-1 h-1 p-2 bg-blue-700 rounded-full'>
                    </div>
                </div>
                <div className='p-3 rounded-full w-fit bg-slate-800'>
                    <div className='w-1 h-1 p-2 bg-blue-700 rounded-full'>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials