import { images } from '../../constants';

import React from 'react';

const Services = () => {
    const services = [
        {
            "image": images.code,
            "title": "Développement web",
            "body": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis repudiandae reiciendis sapiente est minus possimus sunt?"
        },
        {
            "image": images.code,
            "title": "Développement mobile",
            "body": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis repudiandae reiciendis sapiente est minus possimus sunt?"
        },
        {
            "image": images.design,
            "title": "Web Design",
            "body": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis repudiandae reiciendis sapiente est minus possimus sunt?"
        }
    ]

    return (
        <div className='container flex flex-col items-center justify-center py-20 mx-auto gap-14 md:gap-28 md:py-52'>
            <h1 className='text-5xl font-bold md:text-6xl'>Nos Services</h1>
            <div className='grid grid-cols-1 gap-6 md:grid-cols-3'>
                {
                    services.map((service) => {
                        return <div className='flex flex-col justify-center gap-6 px-10 bg-white border rounded-lg md:bg-gray-50 h-80'>
                            <div className='flex flex-col items-center justify-center w-12 h-12 bg-blue-400 rounded-full md:w-16 md:h-16'>
                                <img className='h-6 md:h-10' src={service.image} alt="mail icon" />
                            </div>
                            <h1 className='text-2xl font-semibold'>{service["title"]}</h1>
                            <p className='text-gray-600'>{service["body"]}</p>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default Services