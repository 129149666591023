import './App.css';
import { Hero, Navbar, Projects, Services, Testimonials, Trusters } from './containers/index';
import './styles/background.css';


function App() {
  return (
    <div className='w-full font-inter'>
      <div className='flex flex-col mesh-gradient'>
        <Navbar />
        <Hero className="flex-grow" />
        <Trusters />
         <Services />
        <Projects />
        <Testimonials />
        {/* <Faqs />
        <Footer /> */}
      </div>
      
    </div>
  );
}

export default App;
