import React from 'react'
import { images } from '../../constants'

const Projects = () => {

    const projects = [
        {
            "image": images.team,
            "title": "Project Management",
            "type": "Web design",
            "body": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis repudiandae reiciendis sapiente est minus possimus sunt?"
        },
        {
            "image": images.illustration,
            "title": "Softwares development",
            "type": "Mobile development",
            "body": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis repudiandae reiciendis sapiente est minus possimus sunt?"
        },
    ]

    return (
        <div className='container flex flex-col gap-16 mx-auto md:gap-24'>
            <div className='flex flex-col gap-8 text-center'>
                <h1 className='text-5xl font-bold md:text-6xl'>Find our latest works</h1>
                <h4>The awesome people who makes all this possible</h4>
            </div>
            <div className='grid gap-16 md:gap-6 grid-col-1 md:grid-cols-2'>
                {
                    projects.map((project) => {
                        return <div className='flex flex-col gap-4 '>
                            <img src={project.image} alt={project.title} className="w-full h-3/5 rounded-3xl" />
                            <div className='px-4 py-2 mt-4 mb-2 rounded-full w-fit bg-slate-900'>
                                <h6 className='text-xs text-white'>{project.type}</h6>
                            </div>
                            <h5 className='text-2xl font-semibold'>{project.title}</h5>
                            <p>{project.body}</p>
                            <div className='flex flex-row gap-4 text-blue-700 underline'>
                                <p>Read more</p>
                                <img src={images.arrow} alt="" className='rotate-180' />
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default Projects