import React from 'react';
import { images } from '../../constants';

const Trusters = () => {

    const clients = [
        {
            "logo": images.google,
            "name": "Google",
        },
        {
            "logo": images.warnerBros,
            "name": "Warner Bros",
        },
        {
            "logo": images.mailchimp,
            "name": "Mailchimp",
        },
        {
            "logo": images.adobe,
            "name": "Adobe",
        },
        {
            "logo": images.fiverr,
            "name": "Fiverr",
        },
        {
            "logo": images.creativeCloud,
            "name": "Creative Cloud",
        }
        ,
        {
            "logo": images.canva,
            "name": "Canva",
        }
        ,
        {
            "logo": images.wooCommerce,
            "name": "WooCommerce",
        }

    ];

    return (
        <div className='container flex flex-col gap-16 mx-auto mt-32 mb-20 md:gap-32 md:flex-row md:my-60'>
            <div className='flex flex-col-reverse items-start gap-8 md:items-center md:flex-row'>
                <div className='w-24 h-1 rounded-full bg-slate-800' />
                <h2 className='text-4xl font-semibold'>Meet our<br />recent clients</h2>
            </div>
            <div className='grid flex-1 grid-cols-2 gap-6 md:grid-cols-4'>
                {
                    clients.map((client) => (
                        <div className='flex flex-row items-center gap-4'>
                            <img src={client.logo} alt={client.name} srcset="" className='h-7' />
                            <h5 className='text-sm md:text-lg'>{client.name}</h5>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Trusters