import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import NavbarItem from '../../components/NavbarItem';
import { images } from '../../constants';
import '../../styles/background.css';



const Navbar = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div>
            <div className='container flex flex-row items-center justify-between px-4 py-8 mx-auto md:px-0 '>
                {/* Logo */}
                <div>
                    <img src={images.logo} alt="logo aek" className='h-10 md:h16' data-aos="zoom-out-right" />
                </div>
                {/* Nav items */}
                <div className='hidden text-sm md:flex'>
                    <ul className='flex flex-row gap-8'>
                        <NavbarItem link="A propos de nous" />
                        <NavbarItem link="Nos services" />
                        <NavbarItem link="Projets" />
                        <NavbarItem link="Temoignages" />
                    </ul>
                </div>
                {/* Nav button */}
                <div className='hidden px-4 py-3 text-sm transition-all duration-300 ease-in-out bg-white border border-blue-100 rounded-full cursor-pointer md:block hover:scale-110 hover:text-white hover:font-bold hover:bg-blue-700'>
                    Contactez-nous
                </div>

                {/* Hamburger */}
                <button className='md:hidden'>
                    <HiOutlineMenuAlt3 className='text-2xl' />
                </button>
            </div>
        </div>
    )
}

export default Navbar