import React from 'react';
import HeroEmailField from '../../components/HeroEmailField';
import '../../styles/background.css';
import './Hero.css';

const Hero = () => {
    return (
        <div>
            <div className='flex flex-col items-center justify-center'>
                <div className='h-16 md:h-32' />
                <div className='flex flex-col items-center px-4 md:px-0'>
                    <h4 className='text-xl font-semibold md:text-2xl text-slate-700' data-aos="zoom-in-up" data-aos-duration="1500">With <span className='text-blue-500'>AEK Group</span></h4>
                    <div className='h-6' />
                    <h1 className='text-4xl font-bold text-center md:text-start md:text-6xl' data-aos="zoom-in-up" data-aos-duration="2000">Let's start a project together</h1>
                    <div className='h-4 md:h-8' />
                    <h5 className='text-center text-gray-500 text-md md:text-lg' data-aos="zoom-in-up" data-aos-duration="2500">Lorem ipsum dolor sit amet consectetur adipisicing elit.</h5>
                    <div className='h-12' />
                    <div className='w-full md:w-9/12'>
                        <HeroEmailField />
                    </div>
                </div>
                <div className='h-16 md:h-24' />
                <div className='container flex flex-col w-full gap-6 text-center md:flex-row '>
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-row gap-4'>
                            <div className='flex flex-col justify-center w-1/2 gap-3 p-10 text-white bg-green-100 rounded-lg md:h-56 md:w-60 project-background' data-aos="zoom-in" data-aos-duration="1500">
                                <h1 className='text-5xl font-bold'>2</h1>
                                <h3 className='text-xl'>Projets <br /> en attente</h3>
                            </div>
                            <div className='flex flex-col w-1/2 gap-3 p-10 bg-green-100 rounded-lg md:h-56 md:w-80'>
                                <h1 className='text-5xl font-bold'>453</h1>
                                <h3 className='text-2xl'>Awards</h3>
                            </div>
                        </div>
                        <div className='flex flex-col w-full gap-8 p-10 text-white bg-blue-100 rounded-lg md:h-72 start-project-background' data-aos="zoom-in" data-aos-duration="1500">
                            <h1 className='text-2xl font-medium'>Vous voulez démarrer un projet ?</h1>
                            <div className='flex flex-row gap-4 px-6 py-4 text-sm transition-all duration-300 ease-in-out bg-blue-500 rounded-full cursor-pointer hover:scale-110 hover:bg-white hover:text-blue-500 w-fit bg-red'>
                                {/* <object data={images.email} type=""></object> */}
                                <a href="#contact">Contactez-nous maintenant</a>
                            </div>
                        </div>
                    </div>

                    <div className='flex-1 bg-green-400'>
                        Ici se trouvera une photo illustrative de l'equipe
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero