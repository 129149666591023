import photoshop from '../assets/icons/adobe-photoshop.svg';
import arrow from '../assets/icons/arrow-left-02-sharp.svg';
import arrowWhite from '../assets/icons/arrow-white.svg';
import code from '../assets/icons/code.svg';
import email from '../assets/icons/mail.svg';
import design from '../assets/icons/web-design-02.svg';
import illustration from '../assets/images/02.jpg';
import team from '../assets/images/06.jpg';
import adobe from '../assets/images/adobe.png';
import creativeCloud from '../assets/images/creative-cloud.png';
import facebook from '../assets/images/facebook-02.svg';
import fiverr from '../assets/images/fiverr.png';
import google from '../assets/images/google.png';
import customer2 from '../assets/images/image.png';
import instagram from '../assets/images/instagram.svg';
import logo from '../assets/images/logo.svg';
import mailchimp from '../assets/images/mailchimp.png';
import customer from '../assets/images/onboarding2.jpg';
import canva from '../assets/images/painting.png';
import quotation from '../assets/images/quotation.png';
import twitter from '../assets/images/twitter.svg';
import warnerBros from '../assets/images/warner-bros.png';
import wooCommerce from '../assets/images/woocommerce.png';


export default {
  email,
  photoshop,
  code,
  design,
   team,
   illustration,
   arrow,
   arrowWhite,
   google,
   mailchimp,
   canva,
   warnerBros,
   fiverr,
   creativeCloud,
   adobe,
   wooCommerce,
   customer,
   quotation,
   facebook,
   twitter,
   instagram,
   customer2,
   logo,
};