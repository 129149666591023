import React from 'react'

const HeroEmailField = () => {
    return (
        <div className='flex flex-row w-full py-1 pr-1 bg-gray-100 rounded-full md:pl-6' data-aos="zoom-in" data-aos-duration="3000">
            <input
                type="email"
                id="email"
                name="email"
                placeholder='Veuillez renseiger votre email'
                className='flex-1 px-4 bg-transparent border-none focus:outline-none'
            />
            <button className='px-8 py-3 text-white bg-blue-600 rounded-full md:py-4'>Valider</button>
        </div>
    )
}

export default HeroEmailField